import * as Types from '../../src/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LayoutQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LayoutQueryQuery = { __typename?: 'Query', currentAccount: { __typename?: 'Account', id: string, name?: string | null, email: string, companyId: number, role: Types.AccountRole, company: { __typename?: 'Company', name: string } } };


export const LayoutQueryDocument = gql`
    query LayoutQuery {
  currentAccount {
    id
    name
    email
    companyId
    role
    company {
      name
    }
  }
}
    `;

/**
 * __useLayoutQueryQuery__
 *
 * To run a query within a React component, call `useLayoutQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLayoutQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLayoutQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useLayoutQueryQuery(baseOptions?: Apollo.QueryHookOptions<LayoutQueryQuery, LayoutQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LayoutQueryQuery, LayoutQueryQueryVariables>(LayoutQueryDocument, options);
      }
export function useLayoutQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayoutQueryQuery, LayoutQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LayoutQueryQuery, LayoutQueryQueryVariables>(LayoutQueryDocument, options);
        }
export function useLayoutQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LayoutQueryQuery, LayoutQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LayoutQueryQuery, LayoutQueryQueryVariables>(LayoutQueryDocument, options);
        }
export type LayoutQueryQueryHookResult = ReturnType<typeof useLayoutQueryQuery>;
export type LayoutQueryLazyQueryHookResult = ReturnType<typeof useLayoutQueryLazyQuery>;
export type LayoutQuerySuspenseQueryHookResult = ReturnType<typeof useLayoutQuerySuspenseQuery>;
export type LayoutQueryQueryResult = Apollo.QueryResult<LayoutQueryQuery, LayoutQueryQueryVariables>;