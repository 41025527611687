"use client";
import React, { useMemo } from "react";
import { gql } from "@apollo/client";
import { CacheProvider } from "@chakra-ui/next-js";
import {
  ChakraProvider,
  extendTheme,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Text,
  Link as ExternalLink,
  MenuDivider,
} from "@chakra-ui/react";
import Image from "next/image";
import { ApolloNextAppProvider } from "@apollo/experimental-nextjs-app-support/ssr";
import Sidebar from "~/src/components/molecules/Sidebar";
import { theme } from "~/constants/theme";
import { makeClient } from "~/apis/apolloClient";
import { AuthProvider, useAuth } from "~/contexts/AuthContext";
import { useToast } from "~/src/hooks/useToast";
import { useLayoutQueryQuery } from "./layout.generated";

// import dynamic from "next/dynamic";
// import { ChannelTalkWrapper } from "~/src/components/organisms/ChannelTalk";
import dynamic from "next/dynamic";
import {
  MdOutlineAccountCircle,
  MdArrowDropDown,
  MdLogout,
} from "react-icons/md";

import * as Sentry from "@sentry/nextjs";
import { IoSparklesSharp } from "react-icons/io5";
import { RiQuestionnaireLine } from "react-icons/ri";

const LAYOUT_QUERY = gql`
  query LayoutQuery {
    currentAccount {
      id
      name
      email
      companyId
      role
      company {
        name
      }
    }
  }
`;

const customTheme = extendTheme(theme);

type LayoutProps = {
  children: React.ReactNode;
};

const LazyChannelTalk = dynamic(
  () => import("~/src/components/providers/ChannelTalkProvider"),
  {
    ssr: false,
  },
);

const Main = ({ children }: LayoutProps) => {
  const { loggedIn, logout } = useAuth();
  const toast = useToast();
  const channelIOKey = process.env.NEXT_PUBLIC_ERP_WEB_CHANNEL_TALK_PLUGIN_KEY;

  const { data } = useLayoutQueryQuery({
    fetchPolicy: "cache-and-network",
    skip: !loggedIn,
    onCompleted: (data) => {
      Sentry.setUser({ id: data?.currentAccount.id });
      Sentry.setTags({ companyId: data?.currentAccount.companyId });
    },
  });

  const sidebarItem = useMemo(() => {
    switch (data?.currentAccount?.role) {
      case "ADMIN":
      case "SUPER_ADMIN":
        return "default";
      case "LEADER":
        return "shift_only";
      default:
        return "guest";
    }
  }, [data]);

  const handleLogout = async () => {
    await logout();
    toast({
      title: "ログアウトしました",
      status: "success",
    });
    window?.location?.replace?.("/");
  };

  return (
    <Box bg="white" w="100%">
      <Flex
        as="header"
        width="100%"
        justify="space-between"
        position="fixed"
        bg="white"
        top="0"
        left="0"
        height={14}
        shadow="md"
        p={4}
        zIndex={2}
      >
        <Image
          src="/images/logo.svg"
          priority
          alt="logo"
          width={150}
          height={24}
        />
        {loggedIn ? (
          <Menu>
            <MenuButton>
              <Text display="flex" alignItems="center" gap={2}>
                <Icon as={MdOutlineAccountCircle} size={10} />
                {data?.currentAccount.company.name} |{" "}
                {data?.currentAccount.name}
                <Icon as={MdArrowDropDown} size={10} />
              </Text>
            </MenuButton>
            <MenuList zIndex="2">
              <MenuItem onClick={handleLogout}>
                <Icon as={MdLogout} size={10} mr={2} />
                ログアウト
              </MenuItem>
              <MenuDivider />
              <ExternalLink
                href="https://207-inc.super.site/todocu-cloud-update"
                isExternal
                target="_blank"
                _hover={{
                  textDecoration: "none",
                }}
              >
                <MenuItem>
                  <Icon as={IoSparklesSharp} size={20} mr="8px" />
                  アップデート情報
                </MenuItem>
              </ExternalLink>
              <MenuDivider />
              <ExternalLink
                href="https://207-inc.super.site/todocu-cloud-guide"
                isExternal
                target="_blank"
                _hover={{
                  textDecoration: "none",
                }}
              >
                <MenuItem>
                  <Icon as={RiQuestionnaireLine} size={20} mr="8px" />
                  使い方ガイド
                </MenuItem>
              </ExternalLink>
            </MenuList>
          </Menu>
        ) : null}
      </Flex>
      <Flex paddingTop={14} height="100vh" direction="row">
        <Sidebar variant={sidebarItem} />
        <>
          {channelIOKey && loggedIn ? (
            <LazyChannelTalk
              channelIOKey={channelIOKey}
              profile={
                data
                  ? {
                      name: `[${data.currentAccount.company.name}]${data.currentAccount.name}`,
                      email: data.currentAccount.email,
                      companyName: data.currentAccount.company.name,
                      companyId: String(data.currentAccount.companyId),
                    }
                  : undefined
              }
            >
              <Box
                as="section"
                height="100%"
                overflowY="auto"
                flex={1}
                bgColor="gray.50"
              >
                {children}
              </Box>
            </LazyChannelTalk>
          ) : (
            <Box
              as="section"
              height="100%"
              overflowY="auto"
              flex={1}
              bgColor="gray.50"
              paddingLeft="calc(100vw - 100%)"
            >
              {children}
            </Box>
          )}
        </>
      </Flex>
    </Box>
  );
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <CacheProvider>
      <ChakraProvider resetCSS theme={customTheme}>
        <ApolloNextAppProvider makeClient={makeClient}>
          <AuthProvider>
            <Main>{children}</Main>
          </AuthProvider>
        </ApolloNextAppProvider>
      </ChakraProvider>
    </CacheProvider>
  );
};

export default Layout;
